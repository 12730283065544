// Generated by Framer (57e2ddd)

import * as React from "react";
import { motion, LayoutGroup } from "framer-motion";
import { Image, transformTemplate, useActiveVariantCallback, useOnAppear, addFonts, withCSS, Container, getFonts } from "framer";
import App4 from "../codeFile/App4.tsx";
const App4Fonts = getFonts(App4);

const Screen = React.forwardRef(function({ style, ...restProps }, ref) {

return (<div className="framer-ZITDZ" style={{"display": "contents"}}>
<Container style={{...style}} className="framer-140om98-container" name="SuperDraft | Celebrity Championship Boxing Pick'em Challenge" layoutIdKey="pEH4Uuf9w" duplicatedFrom={["GPNxkR4xT"]} data-framer-name="SuperDraft | Celebrity Championship Boxing Pick'em Challenge"><App4 width="100%" height="100%" layoutId="pEH4Uuf9w" id="pEH4Uuf9w" name="SuperDraft | Celebrity Championship Boxing Pick'em Challenge" style={{"width": style?.width, "height": style?.height}}/></Container>
</div>)

});

const css = [".framer-ZITDZ [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none;}", ".framer-ZITDZ .framer-140om98-container { position: relative; width: 1920px; height: 1080px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 1080
 * @framerIntrinsicWidth 1920
 * @framerSupportedLayoutWidth fixed
 * @framerSupportedLayoutHeight fixed
 * @framerScreen
 */
const FramerScreen140om98: React.ComponentType = withCSS(Screen, css);
export default FramerScreen140om98;

FramerScreen140om98.displayName = "Screens/SuperDraft | Celebrity Championship Boxing Pick'em Challenge";

FramerScreen140om98.defaultProps = {"width": 1920, "height": 1080};

addFonts(FramerScreen140om98, [...App4Fonts]);